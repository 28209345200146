let app = new Vue({
    el: '#app',
    data: {
        isLoading: true,
        isSuccess: false,
        name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        department: null,
        departmentInternational: null,
        position: null,
        positionInternational: null,
        isInternational: true,
        code: null,
        html: null,
        selectedLocation: null,
        selectedBranch: null,
        selectedLanguage: null,
        imageBaseUrl: 'https://signature.kovintrade.com',
        placeholders: {
            sl: {
                placeholder_name: 'Janez',
                placeholder_last_name: 'Novak',
                placeholder_phone: '+386 1 321 321',
                placeholder_mobile: '+386 41 123 123',
                placeholder_email: 'janez.novak@kovintrade.com',
                placeholder_department: 'MPP in informatika',
                placeholder_departmentInternational: 'BPM & IT',
                placeholder_position: 'Direktorica MPP in Informatike',
                placeholder_positionInternational: 'BPM & Informatics Manager',
            },
            en: {
                placeholder_name: 'John',
                placeholder_last_name: 'Doe',
                placeholder_phone: '+386 1 321 321',
                placeholder_mobile: '+386 41 123 123',
                placeholder_email: 'john.doe@kovintrade.com',
                placeholder_department: 'MPP in informatika',
                placeholder_departmentInternational: 'BPM & IT',
                placeholder_position: 'Direktorica MPP in Informatike',
                placeholder_positionInternational: 'BPM & Informatics Manager',
            }
        },
        labels: {
            sl: {
                slogan: 'Generator elektronskega podpisa',
                company: 'Podjetje',
                branch: 'Poslovna enota',
                name: 'Ime',
                last_name: 'Priimek',
                position: 'Pozicija',
                position_international: 'Mednarodna pozicija',
                department: 'Oddelek',
                department_international: 'Mednarodni oddelek',
                international_checkbox: 'Mednarodni naziv pozicije in oddelka?',
                email: 'Elektronski naslov',
                phone: 'Telefon',
                mobile: 'Mobitel',
                preview: 'Predogled',
                success: 'Podpis uspešno kopiran v odložišče. Prilepite ga v vaš poštni odjemalec.',
                central: 'Centrala',
            },
            en: {
                slogan: 'Email signature generator',
                company: 'Company',
                branch: 'Branch',
                name: 'Name',
                last_name: 'Last name',
                position: 'Position',
                position_international: 'Position international',
                department: 'Department',
                department_international: 'Department international',
                international_checkbox: 'Display international position and department?',
                email: 'Email address',
                phone: 'Phone',
                mobile: 'Mobile',
                preview: 'Preview',
                success: 'Signature successfully copied to your clipboard. You can paste it to your favourite mail client.',
                central: 'Central',
            }
        },
        locations: [
            {
                id: 1,
                name: 'Kovintrade d.d. Celje',
                address: 'Mariborska cesta 7',
                city: 'SI - 3000 Celje',
                locale: 'sl',
                web: {
                  url: 'https://kovintrade.si',
                  label: 'www.kovintrade.si',
                },
                socials: {
                  linkedin: 'https://www.linkedin.com/company/kovintrade-d-d-/',
                  facebook: 'https://www.facebook.com/kovintradedd/'
                },
                certs: [
                    {
                        path: '/assets/images/certifikat-1.png',
                        size: {
                            width: 50,
                            height: 50,
                        }
                    },
                    {
                        path: '/assets/images/certifikat-2.png',
                        size: {
                            width: 50,
                            height: 50,
                        }
                    },
                    {
                        path: '/assets/images/certifikat-3.png',
                        size: {
                            width: 91,
                            height: 50,
                        }
                    },
                ],
                branches: [
                    {
                        id: 1,
                        name: 'PE CELJE',
                        address: 'Bežigrajska cesta 4A',
                        city: '3000 Celje',
                    },
                    {
                        id: 2,
                        name: 'PE TRNOVLJE',
                        address: 'Trnoveljska cesta 2E',
                        city: '3000 Celje',
                    },
                    {
                        id: 3,
                        name: 'PE ŠTORE',
                        address: 'Železarska cesta 3',
                        city: '3220 Štore',
                    },
                    {
                        id: 4,
                        name: 'PE LJUBLJANA',
                        address: 'Brnčičeva ulica 45',
                        city: '1231 Ljubljana - Črnuče'
                    },
                ]
            },
            {
                id: 2,
                name: 'Kovintrade Metal d. o. o.',
                address: 'Cesta železarjev 8',
                city: 'SI - 4270 Jesenice',
                locale: 'sl',
                web: {
                    url: 'http://www.kovintrade-metal.si/',
                    label: 'www.kovintrade-metal.si',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-d-d-/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
                certs: [
                    {
                        path: '/assets/images/certifikat-1.png',
                        size: {
                            width: 50,
                            height: 50,
                        }
                    }
                ]
            },
            {
                id: 3,
                name: 'Kovin trade Handelsgesellshaft m.b.h.',
                address: 'August - Jaksch Str. 2',
                city: 'A - 9020 Klagenfurt',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.at',
                    label: 'www.kovintrade.at',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-aussenhandels-gmbh/about/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
            },
            {
                id: 4,
                name: 'Kovintrade s.r.o.',
                address: 'Dúbravská cesta 2',
                city: '841 04 Bratislava - mestská  časť Dubravka, Slovensko',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.sk',
                    label: 'www.kovintrade.sk',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-d-d-/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
                certs: [
                    {
                        path: '/assets/images/certifikat-1.png',
                        size: {
                            width: 50,
                            height: 50,
                        }
                    }
                ]
            },
            {
                id: 5,
                name: 'Kovintrade Hungary Kft.',
                address: 'II. Rákóczi Ferenc út 100',
                city: 'H-2314 Hálasztelek',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.hu',
                    label: 'www.kovintrade.hu',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-d-d-/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
            },

            {
                id: 6,
                name: 'Kovintrade Hrvatska d.o.o.',
                address: 'Ulica Ljudevita Posavskog 29',
                city: '10360 Sesvete, Republika Hrvatska',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.hr',
                    label: 'www.kovintrade.hr',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintradehrvatska/about/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
            },
            {
                id: 7,
                name: 'Kovintrade Bulgaria EOOD',
                address: '5 "Viktor Grigorovich" Str, floor 1, app. 2',
                city: '1606 Sofia, Bulgaria',
                locale: 'en',
                web: {
                    url: 'http://kovintrade.bg',
                    label: 'www.kovintrade.bg',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-d-d-/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
            },
            {
                id: 8,
                name: 'Kovintrade d.o.o. Beograd',
                address: 'Batajnički drum 23',
                city: '11186 Beograd (Zemun), Republika Srbija',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.rs',
                    label: 'www.kovintrade.rs',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-d-o-o-srbija/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
                branches: [
                    {
                        id: 1,
                        name: 'Steel center Kragujevac',
                        address: 'Trg topolivaca 4',
                        city: '34000 Kragujevac, Republika Srbija'
                    }
                ]
            },
            {
                id: 9,
                name: 'Kovintrade Praha spol. s.r.o.',
                address: 'Radlická 714/113a, 158 00 Praha 5 - Jinonice',
                city: 'Avenir Business Park-budova C, Česká republika',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.cz',
                    label: 'www.kovintrade.cz',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-praha-spol-s-r-o-/',
                    facebook: 'https://www.facebook.com/kovintradepraha/',
                    instagram: 'https://www.instagram.com/kovintrade/',
                    youtube: 'https://www.youtube.com/channel/UCiL1wAjCNoTsnfz82qvLaAQ'
                },
                branches: [
                    {
                        id: 2,
                        name: 'Steel centrum Frýdlant nad Ostravicí',
                        address: 'Kúty 1648',
                        city: '739 11 Frýdlant nad Ostravicí'
                    }
                ]
            },
            {
                id: 10,
                name: 'Kovintrade Romania s.r.l.',
                address: 'B-Dul Iuliu Maniu Nr.7,corp A, Etaj 3, Camera B27-B30, sector 6',
                city: '061072, Bucuresti, Romania',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.ro',
                    label: 'www.kovintrade.ro',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintraderomania/about/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
                certs: [
                    {
                        path: '/assets/images/certifikat-1.png',
                        size: {
                            width: 50,
                            height: 50,
                        }
                    }
                ],
                branches: [
                    {
                        id: 3,
                        name: 'Punct de Lucru: Bd.Energeticienilor nr. 13-15, sector 3',
                        address: null,
                        city: 'Cod postal 032091, Bucuresti, Romania'
                    }
                ]
            },
            {
                id: 11,
                name: 'Kovintrade d.o.o. Banja Luka',
                address: 'Bulevar Stepe Stepanovića 181b',
                city: '78000 Banja Luka, Bosna i Hercegovina',
                locale: 'en',
                web: {
                    url: 'https://kovintrade.com',
                    label: 'www.kovintrade.com',
                },
                socials: {
                    linkedin: 'https://www.linkedin.com/company/kovintrade-d-d-/',
                    facebook: 'https://www.facebook.com/kovintradedd/'
                },
            }
        ],
    },
    mounted() {
        this.selectedLocation = this.locations[0]
        this.setLanguage()
        this.isLoading = false
    },
    methods: {
        onCopy() {
            console.log("Action: copy")
            console.log(this.$refs.html.innerHTML)
            const blobInput = new Blob([this.$refs.html.innerHTML], { type: 'text/html' })
            navigator.clipboard.write([new ClipboardItem({ 'text/html': blobInput })])

            this.isSuccess = true

            setTimeout(() => {
                this.isSuccess = false
            }, 3000)
        },
        displayValue(prop) {
            return (this[prop] !== null && this[prop] !== '') ? this[prop] : this.placeholders[this.selectedLanguage][`placeholder_${prop}`]
        },
        getLabel(label) {
            return this.labels[this.selectedLanguage][label]
        },
        getPlaceholder(placeholder) {
            return this.placeholders[this.selectedLanguage][placeholder]
        },
        onSelectedLocationChange()
        {
            this.selectedBranch = null
            this.setLanguage()
        },
        setLanguage() {
            this.selectedLanguage = this.selectedLocation.locale
        },
        formatPhone(phone) {
            return `tel:${phone}`
        },
        formatEmail(email) {
            return `mailto:${email}`
        },
        formatImage(image) {
            return `${this.imageBaseUrl}${image}`
        },
        hasCerts() {
            return this.selectedLocation.hasOwnProperty('certs')
            //return 'certs' in this.selectedLocation
            //return this.selectedLocation.certs && this.selectedLocation.length > 0
        }
    },
})